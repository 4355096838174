import React from "react";
import Routes from "./Routes";
import { BrowserRouter as Router } from "react-router-dom";
//import CssBaseline from "@mui/material/CssBaseline";
//import { createTheme, ThemeProvider } from "@mui/material";
import Layout from "./components/Layout";

// const theme = createTheme({
//   typography: {
//     button: {
//       textTransform: "none",
//     },
//   },
// });

const App = () => (
  <Router>
    <Layout>
      <Routes />
    </Layout>
  </Router>
);

export default App;

import * as React from "react";
//material ui
import AppBar from "@mui/material/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import Container from "@material-ui/core/Container";

const navcontent = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "Teams",
    url: "/bio",
  },
  {
    title: "Services",
    url: "/services",
  },
  {
    title: "Contact",
    url: "/contact",
  },
];

function Copyright() {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      className=""
    >
      {"Copyright © "}
      <Link color="inherit" href="https://Fanatic4Tech.com/">
        Fanatic4Tech
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const Layout = ({ children }) => (
  <React.Fragment>
    <AppBar
      position="fixed"
      color="inherit"
      className="fixed-top"
      elevation={1}
      sx={{
        position: "relative",
        borderBottom: (t) => `1px solid ${t.palette.divider}`,
      }}
    >
      <Toolbar sx={{ flexWrap: "wrap" }}>
        <Typography
          variant="h6"
          color="inherit"
          noWrap
          sx={{ flexGrow: 1 }}
          align="left"
        >
          <Link
            variant="button"
            className="text-dark font-weight-bold"
            href="/"
            style={{
              textDecoration: "none",
              fontSize: "20px",
              textTransform: "none",
            }}
            sx={{ my: 1, mx: 1.5 }}
          >
            <span
              style={{ backgroundColor: "black", fontSize: "15px" }}
              className="p-3 text-light"
            >
              F4T
            </span>{" "}
            Fanatic4Tech
          </Link>
        </Typography>
        <Typography
          variant="h6"
          color="inherit"
          noWrap
          sx={{ flexGrow: 1 }}
          align="right"
          className="list-inline"
        >
          {navcontent.map((data) => (
            <Link
              href={data.url}
              style={{ textDecoration: "none", fontSize: "14px" }}
              className="list-inline-item mx-2 font-weight-bold"
            >
              {data.title}
            </Link>
          ))}
          <Button
            className="list-inline-item mx-2 font-weight-bold"
            variant="outlined"
            href="#"
          >
            Log in
          </Button>
        </Typography>
      </Toolbar>
    </AppBar>
    <Container component="main" maxWidth="xl" sx={{ mb: 4 }}>
      {children}
      <Copyright />
    </Container>
  </React.Fragment>
);

export default Layout;
